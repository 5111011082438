(function ($, root, undefined) {

  $(function () {

    'use strict'

    // Scroll to top
    var btn = $('#button')
    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass('show')
      } else {
        btn.removeClass('show')
      }
    })

    btn.on('click', function (e) {
      e.preventDefault()
      $('html, body').animate({ scrollTop: 0 }, '300')
    })

    // Scroll to anchor
    let $root = $('html, body')
    $('a[href^="#"]').click(function () {
      if ($(this).hasClass('.anchor-link')) {
        let href = $.attr(this, 'href')
        $root.animate({ scrollTop: $(href).offset().top }, 500, function () {
          window.location.hash = href
        })
        return false
      }
    })

    // Fonction fixe navigation
    fixeNav()

    function fixeNav () {
      var lastScrollTop = 0
      var threshold = 200

      $(window).scroll(function (event) {
        var currentScrollTop = $(this).scrollTop()

        if (currentScrollTop > lastScrollTop && lastScrollTop > threshold) {
          $('.header').addClass('stickhidden')
        } else {
          $('.header').removeClass('stickhidden')
        }

        lastScrollTop = currentScrollTop
      })
    }

    // Ferme les menus au clic sur le reste de la page
    $('html').click(function () {
      if ($('.vous-etes').hasClass('active')) {
        $('.vous-etes').removeClass('active')
      }
      if ($('.choix-cite').hasClass('active')) {
        $('.choix-cite').removeClass('active')
      }
    })

    // Dropdown menu Polylang
    $('.header-menu-container-top .pll-parent-menu-item').click(function (event) {
      $('.header-menu-container-top .pll-parent-menu-item ul').toggle()
      if ($('.header-menu-container-top .pll-parent-menu-item').hasClass('active')) {
        $('.header-menu-container-top .pll-parent-menu-item').removeClass('active')
      } else {
        $(this).addClass('active')
      }
      //event.stopPropagation();
      //event.preventDefault();
      //return false;
    })
    $(document).bind('click', function (e) {
      var $clicked = $(e.target)
      if (!$clicked.parents().hasClass('pll-parent-menu-item')) {
        $('.header-menu-container-top .pll-parent-menu-item').removeClass('active')
        $('.header-menu-container-top .pll-parent-menu-item ul').hide()
      }
    })

    // Dropdown menu Vous êtes
    $('.vous-etes').on('click', function (event) {
      $('.vous-etes').toggleClass('active')
      event.stopPropagation()
      //event.preventDefault(); Mis en commentaire car désactive les liens de l'arborescence
    })

    // Dropdown menu Cité
    $('.choix-cite').on('click', function (event) {
      $('.choix-cite').toggleClass('active')
      event.stopPropagation()
      //event.preventDefault(); Mis en commentaire car désactive les liens de l'arborescence
    })

    // Bouton de Recherche
    $('.i-recherche, .btnClose').on('click', function (event) {
      $('.searchForm').toggleClass('active')
      $(this).toggleClass('active')
      $('.search-input').trigger('focus')
    })
    // Ferme la recherche plein écran quand on tape ESC
    $(document).on('keyup', function (e) {
      if (e.key == 'Escape' && $('.searchForm').hasClass('active')) {
        $('.searchForm').toggleClass('active')
      }
    })

    // Dropdown menu Choisissez une cité
    $('.menu-dyn').on('click', function (event) {
      $(this).toggleClass('active')
      event.stopPropagation()
      //event.preventDefault(); Mis en commentaire car désactive les liens de l'arborescence
    })

    // Menu fullscreen
    $('.menu-button').click(function (event) {

      // Affiche le menu
      if ($('.menu-fullscreen').hasClass('display')) {
        $('.menu-fullscreen').removeClass('display')
      } else {
        $('.menu-fullscreen').addClass('display')
      }

      // Ajoute la couleur au header
      if ($('.header').hasClass('openmenu')) {
        $('.header').removeClass('openmenu')
      } else {
        $('.header').addClass('openmenu')
      }

      // Enlève le scroll sur le body
      if ($('body').hasClass('disablescroll')) {
        $('body').removeClass('disablescroll')
      } else {
        $('body').addClass('disablescroll')
      }
      event.stopPropagation()
      event.preventDefault()
      return false
    })

    $('.accordionjs').accordionjs()

    // jQuery DatePicker date format
    $('.date_picker').datepicker({
      dateFormat: 'dd/mm/yy'
    })

    // Formulaire de filtre

    var x, i, j, l, ll, selElmnt, a, b, c
    /* Look for any elements with the class "form-filter-dropdown": */
    x = document.getElementsByClassName('form-filter-dropdown')
    l = x.length
    for (i = 0; i < l; i++) {
      selElmnt = x[i].getElementsByTagName('select')[0]
      ll = selElmnt.length
      /* For each element, create a new DIV that will act as the selected item: */
      a = document.createElement('DIV')
      a.setAttribute('class', 'select-selected')
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML
      x[i].appendChild(a)
      /* For each element, create a new DIV that will contain the option list: */
      b = document.createElement('DIV')
      b.setAttribute('class', 'select-items select-hide')
      for (j = 1; j < ll; j++) {
        /* For each option in the original select element,
        create a new DIV that will act as an option item: */
        c = document.createElement('DIV')
        c.innerHTML = selElmnt.options[j].innerHTML
        c.addEventListener('click', function (e) {
          /* When an item is clicked, update the original select box,
          and the selected item: */
          var y, i, k, s, h, sl, yl
          s = this.parentNode.parentNode.getElementsByTagName('select')[0]
          sl = s.length
          h = this.parentNode.previousSibling
          for (i = 0; i < sl; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i
              h.innerHTML = this.innerHTML
              y = this.parentNode.getElementsByClassName('same-as-selected')
              yl = y.length
              for (k = 0; k < yl; k++) {
                y[k].removeAttribute('class')
              }
              this.setAttribute('class', 'same-as-selected')
              break
            }
          }
          h.click()
        })
        b.appendChild(c)
      }
      x[i].appendChild(b)
      a.addEventListener('click', function (e) {
        /* When the select box is clicked, close any other select boxes,
        and open/close the current select box: */
        e.stopPropagation()
        closeAllSelect(this)
        this.nextSibling.classList.toggle('select-hide')
        this.classList.toggle('select-arrow-active')
      })
    }

    function closeAllSelect (elmnt) {
      /* A function that will close all select boxes in the document,
      except the current select box: */
      var x, y, i, xl, yl, arrNo = []
      x = document.getElementsByClassName('select-items')
      y = document.getElementsByClassName('select-selected')
      xl = x.length
      yl = y.length
      for (i = 0; i < yl; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i)
        } else {
          y[i].classList.remove('select-arrow-active')
        }
      }
      for (i = 0; i < xl; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add('select-hide')
        }
      }
    }

    /* If the user clicks anywhere outside the select box,
    then close all select boxes: */
    document.addEventListener('click', closeAllSelect)

    // Fin du formulaire de filtre

    $('.tablinks').click(function (event) {
      event.preventDefault()
      $(this).addClass('active')
      $(this).siblings().removeClass('active')
      var tab = $(this).attr('event')
      // find parent with tabcontainer attribute
      var tabContainer = $(this).closest('[tabcontainer]')
      tabContainer.find('.tabcontent').not(tab).css('display', 'none')
      tabContainer.find(tab).fadeIn()
    })

    initSite()

    function initSite () {
      // console.log("INIT SITE");

      if ($('html').hasClass('no-touch')) {

      }

      // Si l'on est sur la Home

      if ($('body').hasClass('home')) {

      } else {
        // console.log("Pas la Home !!");
      }

      // Effetts apparitions
      // https://scrollrevealjs.org/api/reveal.html

      // window.sr = ScrollReveal({ reset: false });

      // sr.reveal('.effetReveal', {
      //   duration: 600,
      //   delay: 300,
      //   scale: 1,
      //   mobile: false,
      //   useDelay: 'once',
      //   origin: 'left',
      //   distance: '200px',
      //   viewFactor: 0.3
      // });

      $('#sliderAgenda').owlCarousel({
        loop: false,
        dots: false,
        margin: 10,
        nav: true,
        navText: ['<div class=\'nav-btn sprite prev-slide\'></div>', '<div class=\'nav-btn sprite next-slide\'></div>'],
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 3
          },
          1200: {
            items: 4
          }
        }
      })

      $('.sliderVignettes').each(function () {
        $(this).owlCarousel({
          loop: false,
          dots: false,
          margin: 10,
          nav: true,
          navText: ['<div class=\'nav-btn sprite prev-slide\'></div>', '<div class=\'nav-btn sprite next-slide\'></div>'],
          responsive: {
            0: {
              items: 1
            },
            600: {
              items: 2
            },
            900: {
              items: 3
            },
            1200: {
              items: 4
            }
          }
        })
      })

      $('#sliderActus').owlCarousel({
        nav: true,
        slideSpeed: 600,
        paginationSpeed: 600,
        smartSpeed: 600,
        singleItem: true,
        items: 1,
        loop: true,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true
      })

      $('#sliderPhotos').owlCarousel({
        nav: false,
        slideSpeed: 600,
        paginationSpeed: 600,
        smartSpeed: 600,
        singleItem: true,
        items: 1,
        loop: false,
        autoplay: true
      })

      $('#sliderTextImage').owlCarousel({
        nav: false,
        slideSpeed: 600,
        paginationSpeed: 600,
        smartSpeed: 600,
        singleItem: true,
        items: 1,
        loop: false,
        autoplay: true
      })

      var sliderText = $('.sliderText')
      var sliderTextSettings = {
        items: 1,
        singleItem: true,
        loop: true,
        margin: 10,
        dots: false,
        pagination: false,
        nav: false,
        touchDrag: false,
        slideBy: 1,
        mouseDrag: false
      }
      sliderText.owlCarousel(sliderTextSettings)

      var sliderNavText = $('.sliderNavText')
      var sliderNavTextSettings = {
        items: 3,
        center: true,
        singleItem: true,
        loop: true,
        margin: 0,
        pagination: false,
        nav: true,
        touchDrag: true,
        slideBy: 1,
        mouseDrag: true
      }
      sliderNavText.owlCarousel(sliderNavTextSettings)

      var sliderFooterLogo = $('.sliderFooterLogo')
      var sliderFooterLogoSettings = {
        center: true,
        margin: 10,
        pagination: false,
        nav: false,
        slideSpeed: 600,
        smartSpeed: 600,
        loop: true,
        autoplay: true,
        slideBy: 1,
        touchDrag: true,
        mouseDrag: true,
        responsive: {
          0: {
            items: 2
          },
          600: {
            items: 2
          },
          900: {
            items: 3
          },
          1200: {
            items: 4
          }
        }
      }
      sliderFooterLogo.owlCarousel(sliderFooterLogoSettings)

      //Sync sliderText by sliderNavText
      sliderNavText.on('click onDragged', '.owl-next', function () {
        sliderText.trigger('next.owl.carousel')
      })

      sliderNavText.on('click dragged.owl.carousel', '.owl-prev', function () {
        sliderText.trigger('prev.owl.carousel')
      })

      sliderNavText.on('translate.owl.carousel', function (e) {
        sliderText.trigger('to.owl.carousel', e.page.index * sliderTextSettings.slideBy)
      })
      //Sync sliderNavText by sliderText
      sliderText.on('click onDragged', '.owl-next', function () {
        sliderNavText.trigger('next.owl.carousel')
      })

      sliderText.on('click dragged.owl.carousel', '.owl-prev', function () {
        sliderNavText.trigger('prev.owl.carousel')
      })

      /*       $('.sliderNavText').owlCarousel({
              nav: false,
              dots: true,
              dotsData: true,
              slideSpeed : 600,
              paginationSpeed: 600,
              smartSpeed: 600,
              items: 3,
              loop: true,
              autoplay: true,

            }); */

      $('#carousel-photos').owlCarousel({
        loop: true,
        center: true,
        margin: 10,
        nav: true,
        navText: ['<div class=\'nav-btn sprite prev-slide\'></div>', '<div class=\'nav-btn sprite next-slide\'></div>'],
        items: 4,
        responsive: {
          0: {
            items: 2
          },
          600: {
            items: 3
          },
          1000: {
            items: 4
          }
        }
      })

    }

    // AFTER INIT SITE
  })

})(jQuery, this);

(function ($, undefined) {
  var isFired = false
  var oldReady = jQuery.fn.ready
  $(function () {
    isFired = true
    $(document).ready()
  })
  jQuery.fn.ready = function (fn) {
    if (fn === undefined) {
      $(document).trigger('_is_ready')
      return
    }
    if (isFired) {
      window.setTimeout(fn, 1)
    }
    $(document).bind('_is_ready', fn)
  }
})(jQuery)
