import '@css/main.scss'

import FsLightbox from 'fslightbox'
import ScrollReveal from 'scrollreveal'
import AOS from 'aos'

AOS.init({
    startEvent: 'load',
    once: true,
    duration: 800,
});

AOS.refresh()

import './scripts.js'
import './accordion.min.js'
import './owl.carousel.min.js'
